import * as React from 'react';
import './About.scss';
import {useTranslation} from "react-i18next";

interface IProps {
}

const About: React.FC<IProps> = () => {
    const {t} = useTranslation();
    return (
        <div className={'about'}>
            <div className={'about-text'}>
                <h1>{t('hi')}</h1>
                <p className={'about-text--body'}>{t('aboutme')}
                </p>
            </div>
        </div>
    );
};

export default About;
