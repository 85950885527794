import * as React from "react";
import './LightDarkMode.scss';
import sun from "../../img/sun.svg";
import moon from "../../img/moon.svg";
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store';
import {toggleDarkMode} from '../../store/features/theme/themeSlice';

interface IProps {
}

const LightDarkMode: React.FC<IProps> = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const dispatch: AppDispatch = useDispatch();

    return (
        <div className={'light-dark'}>
            <input type="checkbox" id="darkmode-toggle" onChange={() => dispatch(toggleDarkMode())}
                   checked={isDarkMode}/>
            <label htmlFor="darkmode-toggle">
                <img className={'sun'} src={sun} alt={'sun'}/>
                <img className={'moon'} src={moon} alt={'moon'}/>
            </label>
        </div>
    );
};

export default LightDarkMode;
