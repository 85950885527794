import * as React from "react";
import './Skills.scss';
import backend from "../../img/backend.png";
import frontend from "../../img/frontend.png";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

interface IProps {
}

const Skills: React.FC<IProps> = () => {
    const {t} = useTranslation();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    return (
        <div className={'skills'}>
            <div className={`skills-box ${isDarkMode ? '' : 'white-mode'}`}>
                <div className={'skills-box--columns'}>
                    <div className={'skills-box__content'}>
                        <div className={'skills-box__content--icon'}>
                            <img src={backend} alt="Backend Icon"/>
                        </div>
                        <h1>Backend</h1>
                        <p className={'skills-box__content--text'}>
                            {t('backend')}
                        </p>
                        <p className={'skills-box__content--header'}>
                            {t('enjoy')}
                        </p>
                        <p className={'skills-box__content--programming-languages'}>
                            Java, Spring Boot, RESTful, Microservices
                        </p>
                        <p className={'skills-box__content--header'}>
                            {t('tools')}
                        </p>
                        <ul>
                            <li>Intellij IDEA &#x2764;</li>
                            <li>Eclipse</li>
                            <li>Git</li>
                        </ul>
                    </div>
                    <div className={'skills-box__content'}>
                        <div className={'skills-box__content--icon'}>
                            <img src={frontend} alt="Frontend Icon"/>
                        </div>
                        <h1>Frontend</h1>
                        <p className={'skills-box__content--text'}>
                            {t('frontend')}
                        </p>
                        <p className={'skills-box__content--header'}>
                            {t('speak')}
                        </p>
                        <p className={'skills-box__content--programming-languages'}>
                            Javascript, Typescript, HTML, CSS, Sass, React
                        </p>
                        <p className={'skills-box__content--header'}>
                            {t('tools')}
                        </p>
                        <ul>
                            <li>Intellij IDEA &#x2764;</li>
                            <li>VS Code</li>
                            <li>Redux</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Skills;
