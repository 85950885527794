import React from 'react';
import './LanguageToggle.scss';
import {useTranslation} from 'react-i18next';

interface IProps {
    onChange: (lang: string) => void;
}

const LanguageToggle: React.FC<IProps> = (props) => {
    const {i18n} = useTranslation();

    const handleToggleChange = async () => {
        props.onChange(i18n.language === 'en' ? 'tr' : 'en');
    };

    return (
        <div className={`language-toggle ${i18n.language ? 'en' : 'tr'}`} onClick={handleToggleChange}>
            {i18n.language === 'en' ? (
                <span className="flag en"></span>
            ) : (
                <span className="flag tr"></span>
            )}
        </div>
    );
};
export default LanguageToggle