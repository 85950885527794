import React from 'react';
import './Navbar.scss';
import logo from "../../img/logo.png";
import resume from "../../img/ErayAyaz.pdf";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import {useTranslation} from "react-i18next";
import LightDarkMode from "../LightDarkMode/LightDarkMode";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {saveAs} from 'file-saver';

const Navbar: React.FC = () => {
    const {t, i18n} = useTranslation();
    const handleLanguageChange = async (newLang: string) => {
        await i18n.changeLanguage(newLang);
    };
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    const handleDownload = () => {
        saveAs(resume, 'ErayAyaz_Resume.pdf');

        fetch(resume)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);

                // Open the HTML wrapper in a new tab with the PDF URL as a query parameter
                const viewerUrl = `${window.location.origin}/pdf-viewer.html?pdf=${encodeURIComponent(url)}`;
                window.open(viewerUrl, '_blank');

                // Clean up the blob URL after a delay to ensure the new tab has time to load
                setTimeout(() => URL.revokeObjectURL(url), 1000);
            })
            .catch(error => console.error('Error fetching the PDF:', error));
    };

    return (
        <nav className="navbar">
            <div className={'navbar-logo--place'}>
                <img className={'navbar-logo'} src={logo} alt="Logo Icon"/>
            </div>
            <div className="navbar-right">
                <div className="navbar-controls">
                    <div>
                        <LightDarkMode/>
                    </div>
                    <div className={'navbar-language-toggle'}>
                        <LanguageToggle onChange={handleLanguageChange}/>
                    </div>
                    <button onClick={handleDownload}
                            className={`navbar-resume-button ${isDarkMode ? '' : 'white-mode'}`}>
                        {t('resume')}
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;