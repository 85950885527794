import React from 'react';
import './Sidebar.scss';
import linkedin from '../../img/linkedin.svg';
import github from '../../img/github.svg';
import instagram from '../../img/instagram.svg';
import twitter from '../../img/twitter.svg';

const Sidebar: React.FC = () => {
    return (
        <div className="sidebar">
            <div className={'sidebar-item'}>
                <a className={'sidebar-href'} href="https://www.linkedin.com/in/erayayaz" target="_blank"
                   rel="noopener noreferrer" style={{color: 'black'}}>
                    <img className={'sidebar-href__image'} src={linkedin} alt={'linkedin'}/>
                </a>
            </div>
            <div className={'sidebar-item'}>
                <a className={'sidebar-href'} href="https://github.com/erayayaz" target="_blank"
                   rel="noopener noreferrer"
                   style={{color: 'black'}}>
                    <img className={'sidebar-href__image'} src={github} alt={'github'}/>
                </a>
            </div>
            <div className={'sidebar-item'}>
                <a className={'sidebar-href'} href="https://twitter.com/erayayazz" target="_blank"
                   rel="noopener noreferrer" style={{color: 'black'}}>
                    <img className={'sidebar-href__image'} src={twitter} alt={'twitter'}/>
                </a>
            </div>
            <div className={'sidebar-item'}>
                <a className={'sidebar-href'} href="https://www.instagram.com/erayayazz/" target="_blank"
                   rel="noopener noreferrer" style={{color: 'black'}}>
                    <img className={'sidebar-href__image'} src={instagram} alt={'instagram'}/>
                </a>
            </div>
        </div>
    );
};

export default Sidebar;
