import * as React from 'react';
import peter from '../../img/peter.png';
import {useTranslation} from 'react-i18next';
import './Home.scss';
import Sidebar from "../Sidebar/Sidebar";

interface IProps {
}

const Home: React.FC<IProps> = () => {
    const {t} = useTranslation();

    const handleButtonClick = () => {
        const subject = 'Hello Eray';
        const email = 'eray19ayaz@gmail.com';

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

        const anchor = document.createElement('a');
        anchor.href = mailtoLink;
        anchor.style.display = 'none';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    return (
        <div className={'home'}>
            <Sidebar/>
            <h1 className={'home-header'}>{t('welcome')}</h1> <br/>
            <h1 className={'home-header'}>{t('whoami')}</h1>
            <img className={'peter'} src={peter} alt="Peter Icon"/>
            <button className={'home-contact-button'} onClick={handleButtonClick}>
                {t('sayhello')}
            </button>
        </div>
    );
};

export default Home;
