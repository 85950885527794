import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import {BrowserRouter} from 'react-router-dom'
import {store} from './store';
import {Provider} from 'react-redux';

console.log("Hi, I'm Eray Ayaz. If you have any feedback about my portfolio site," +
    " I would appreciate it if you could get back to me via email. Happy Coding :)");

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);