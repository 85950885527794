import React, {useState} from 'react';
import './App.scss';
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Skills from "./components/Skills/Skills";
import InfiniteScroll from "react-infinite-scroll-component";
import Project from "./components/Project/Project";
import Navbar from "./components/Navbar/Navbar";
import {useSelector} from 'react-redux';
import {RootState} from './store';
import Footer from "./components/Footer/Footer";
import BuyMeACoffee from "./components/BuyMeCoffee/BuyMeCoffee";

function App() {
    const [showComponents, setShowComponents] = useState(false);

    const loadMore = () => {
        setShowComponents(true);
    };

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    return (
        <div className={`App ${isDarkMode ? '' : 'white-mode'}`}>
            <Navbar/>
            <section className={'section-home'}>
                <Home/>
            </section>
            <BuyMeACoffee />
            {!showComponents &&
                <div className={'App-scroll'}>
                    <div className="scroll-downs">
                        <div className={`mousey ${isDarkMode ? '' : 'white-mode'}`}>
                            <div className={`scroller ${isDarkMode ? '' : 'white-mode'}`}></div>
                        </div>
                    </div>
                </div>
            }
            <InfiniteScroll
                dataLength={showComponents ? 1 : 0} // Set the data length based on whether components should be rendered
                next={loadMore}
                hasMore={!showComponents} // Indicate whether there is more to load
                loader={<h4>Loading...</h4>}>
                {showComponents &&
                    <section className={'section-about'}>
                        <About/>
                    </section>}
                {showComponents &&
                    <section className={'section-skills'}>
                        <Skills/>
                    </section>
                }
                {showComponents &&
                    <section className={'section-project'}>
                        <Project/>
                    </section>
                }
                {showComponents &&
                    <section className={'section-footer'}>
                        <Footer/>
                    </section>
                }
            </InfiniteScroll>
        </div>
    );
}

export default App;
