import React from 'react';
import './Project.scss';
import logo from "../../img/dblogo.png";
import externalLink from "../../img/externalLink.svg";
import {useTranslation} from "react-i18next";

const Project: React.FC = () => {
    const {t} = useTranslation();

    const handleButtonClick = () => {
        window.open('https://www.dbtransfer.com.tr/', '_blank');
    };

    return (
        <div className='project'>
            <h1 className='project-header'>{t('recentWork')}</h1>
            <div className='project-item'>
                <div className='logo-container'>
                    <img src={logo} alt="DB Transfer" className='item-image'/>
                </div>
                <div className='info-container'>
                    <p className='info-text'>
                        {t('dbTransfer')}
                    </p>
                    <button className='visit-button' onClick={handleButtonClick}>
                        Visit Site <img className={'visit-button__icon'} src={externalLink} alt={'externalLink'}/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Project;
