import React, { useEffect } from 'react';

const BuyMeACoffee: React.FC = () => {
    useEffect(() => {
        const script = document.createElement('script');
        const div = document.getElementById('supportByBMC');

        script.setAttribute('src', 'https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js');
        script.setAttribute('data-name', 'BMC-Widget');
        script.setAttribute('data-cfasync', 'false');
        script.setAttribute('data-id', 'erayayaz');
        script.setAttribute('data-description', 'Support me on Buy me a coffee!');
        script.setAttribute('data-message', 'Thank you for the support! 🎉');
        script.setAttribute('data-color', '#5F7FFF');
        script.setAttribute('data-position', 'Right');
        script.setAttribute('data-x_margin', '18');
        script.setAttribute('data-y_margin', '18');

        script.onload = () => {
            const event = new CustomEvent('DOMContentLoaded');
            window.dispatchEvent(event);
        };

        if (div) {
            div.appendChild(script);
        }
    }, []);

    return <div id={'supportByBMC'} className={'bmc-widget-container'}></div>;
};

export default BuyMeACoffee;