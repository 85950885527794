import * as React from 'react';
import './Footer.scss';
import logo from "../../img/logo.png";
import copyright from "../../img/copyright.svg";
import {ReactComponent as LinkedIn} from '../../img/linkedin2.svg';
import {ReactComponent as Github} from '../../img/github2.svg';
import {ReactComponent as Twitter} from '../../img/twitter.svg';
import {ReactComponent as Instagram} from '../../img/instagram.svg';
import {useTranslation} from "react-i18next";

interface IProps {
}

const Footer: React.FC<IProps> = () => {
    const {t} = useTranslation();


    return (
        <div className={'footer'}>
            <div className={'footer__left'}>
                <img className={'footer-logo'} src={logo} alt="Logo Icon"/>
            </div>
            <div className={'footer__center'}>
                <p>{t('developedByMe')} <img className={'footer__center-copyright-icon'} src={copyright} alt={'copyright'} />{t('2024')}</p>
            </div>
            <div className={'footer__right'}>
                <div className={'footer-item'}>
                    <a className={'footer-href'} href="https://www.linkedin.com/in/erayayaz" target="_blank"
                       rel="noopener noreferrer" style={{color: 'black'}}>
                        <LinkedIn className={'footer-href__image'}/>
                    </a>
                </div>
                <div className={'footer-item'}>
                    <a className={'footer-href'} href="https://github.com/erayayaz" target="_blank"
                       rel="noopener noreferrer"
                       style={{color: 'black'}}>
                        <Github className={'footer-href__image'}/>
                    </a>
                </div>
                <div className={'footer-item'}>
                    <a className={'footer-href'} href="https://twitter.com/erayayazz" target="_blank"
                       rel="noopener noreferrer" style={{color: 'black'}}>
                        <Twitter className={'footer-href__image'}/>
                    </a>
                </div>
                <div className={'footer-item'}>
                    <a className={'footer-href'} href="https://www.instagram.com/erayayazz/" target="_blank"
                       rel="noopener noreferrer" style={{color: 'black'}}>
                        <Instagram className={'footer-href__image'}/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
